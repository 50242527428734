import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter  , Routes, Route , useParams   } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import {Button} from 'react-bootstrap';
import {Card} from 'react-bootstrap';
import {CardGroup} from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import { blue, blueGrey, grey } from '@mui/material/colors';
import Cookies from 'universal-cookie';
import InputGroup from 'react-bootstrap/InputGroup';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Buttonm from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import Typography from '@mui/material/Typography';
import { useStopwatch } from 'react-timer-hook';

import Navbar from './components/Navbar'

import AboveNavbar from './components/AboveNavbar'
import { ThefirstContext } from "./ThefirstContext";
import './base.css';

const Sweetdetails = () => {

  const firstContext = React.useContext(ThefirstContext);
  
let nodev ;
  const left_value = useRef(null);
  const vidRef = useRef(null);
  const audRef = useRef(null);
  const  cont_vidRef = useRef(null);
  const  cont_ov_vidRef = useRef(null);
  const  cont_ov2_vidRef = useRef(null);
  const  my_controls = useRef(null);
  const  numidr = useRef(null);
  const  control_top = useRef(null);
  const  slider_top = useRef(null);
  const  mask_phone_class_styling = useRef(null);
  const  phone_number = useRef(null);
  const  calculations = useRef("");
  const  btn1 = useRef(null);
  const  isvideoplay = useRef(false);
  const  btn_image_src = useRef(null);
  const total_current_time = useRef(0);
  const previous_video_time = useRef(0);
  const jump_limit_value = useRef(3);
  const jump_step_value = useRef(0);
  const mask_height_useref = useRef(0);
  const mask_width_useref = useRef(0);
  const xsrrc = useRef("");  // .mp4   
  const audio_xsrrc = useRef(""); // .mp3    
  const type_of_media = useRef(false); //      
  const footer_top = useRef(0);
  const time_current = useRef(0);
  const converted_time_current = useRef(0);
  const converted_time_total = useRef(0);
  const from_source_time_total = useRef(0);

  const  theback = useRef(null);
  
  //---------------------------------------------------------
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });
  //--------------------------------------------------
  React.useEffect(() => {   

    var xx=window.innerWidth/12;
    var xx2=3*( window.innerWidth/5 );
    left_value.current=xx;
    //-------------------------------------------------
    if ( thesweet.current.length > 0)
    { 
        nodev =document.getElementById('vid1');
      if (nodev !==null)
        {
   
    let nodeStylev = window.getComputedStyle(nodev);
    let vwidth = nodeStylev.getPropertyValue('width');
    let vwidth_in_num= parseInt(vwidth ,10);
    let vheight_in_num = ( vwidth_in_num* 9.0)/16.0 ;
    
    mask_height=vheight_in_num+50;
    mask_height_useref.current=mask_height;
    //----------------------------------------------------
    
    cont_vidRef.current.style.width=xx2+"px"; 

    cont_vidRef.current.style.justifyContent="center"; 
    cont_vidRef.current.style.display="flex"; 
    
    vidRef.current.style.width=xx2+"px"; 
    cont_vidRef.current.style.height=(vheight_in_num)+"px"; 
    cont_vidRef.current.style.top=0+"px";
    cont_vidRef.current.style.left=left_value.current+"px";
    cont_vidRef.current.style.zIndex=100;
        }
    }

  }, [{seconds}]);

   const product = [{
    name: null,  code: null,  qty: null,  pricebeforedis:null,
    priceafterdis:null,  discount:null,  expire:null,  note: null,
    user:null,  pass:null,  points:null,  mobile:null,  address:null,
    location:null,  branch:null, 
   }];

  const navigation = useNavigate();
  const params = useParams();

  const theparamscat=useRef("");// <any[]>  
  const thexxi=useRef(0);// <any[]>  
  

 
const cookies = new Cookies();
   
  const theimage=useRef(process.env.REACT_APP_BASE_URL+'/images/products/giphy.gif');
  const [ustttheimage,setustttheimage]=useState(process.env.REACT_APP_BASE_URL+'/images/products/giphy.gif');
  const [srrc, setsrrc] = React.useState("https://st36619.ispot.cc/100_R1_30_1_vc"); //  .mp4
  
  const [activeIndex, setActiveIndex] = useState(0);
  const [mainimage, setmainimage] = useState(0);

  const net_price=useRef(0) ;
  const has_discount=useRef(false) ;

  let mask_height=0;
  //  net_price  has_discount
  
  const thesweet=useRef([]);//   
//  const qty=useRef(1);// 
  const [qty,setqty]=useState(1);

  var sweets=async()=>{
     // var   res0 = await axios.get(process.env.REACT_APP_BASE_URL+'/api/Product/selectsweet');
      console.log("thesweet");
        //------------------------------------------------
        const userLogsInOptions = (xx) => {
 
          console.log("thexx");
          console.log(xx);

          const formData = new URLSearchParams();

          formData.append('productcategory', xx );
          return (
            {
              method: 'POST',
              headers: {
                // "Content-Type": "application/json; charset=utf-8",
                "Content-Type": "application/x-www-form-urlencoded",
            },
              body: formData.toString(),
            json: true,
          }
          );
        };
        
  //-------------------------------------------------
  const response = await fetch(process.env.REACT_APP_BASE_URL+'/api/Product/selectsweetbycategory',
  userLogsInOptions(theparamscat.current) )
  const responseJson = await response.json();
    
  //------------------------------------------------------
  thesweet.current=[];

      thesweet.current=  responseJson ;
      console.log(thesweet.current);  // select_sweet_by_category   productcategory
      console.log("theimage.current");
      //-----------------------------    decide image or video     -----------
      console.log( "paramsi" );
      console.log(  paramsi   );
     
     
      console.log("thesweet.current[paramsi-1]['productproducer']");
      console.log(thesweet.current[paramsi-1]['productproducer']);
     
      var v_or_i_data=thesweet.current[paramsi-1]['productproducer'];
      var arr_dots=v_or_i_data.split('.');
      console.log("arr_dots");
      console.log( arr_dots );
       var last_one=arr_dots[arr_dots.length-1];
       console.log("last_one");
       console.log( last_one );
       

      console.log(v_or_i_data);
      if (last_one==="mp4")
       {
          type_of_media.current=true; //      

       }
       else{
          type_of_media.current=false; //  
       }
      //------------------------------------------
      var sweet_obj=thesweet.current;
      console.log("thexxi.current");
      console.log(thexxi.current);
//--------------------------- net_price  has_discount ---------------------------------

if ( thesweet.current[thexxi.current]['productdiscount'] !=='0' )
{  
  has_discount.current=true; 
  var pri=Number(  thesweet.current[thexxi.current]['productprice']  );
  var dis=Number(  thesweet.current[thexxi.current]['productdiscount']  );
  
  net_price.current =pri - (dis * pri /100) ;
  var temp_num=net_price.current.toFixed(2);
  net_price.current=Number(temp_num);
}
else
{
  net_price.current=Number(  thesweet.current[thexxi.current]['productprice']  );
}

setsrrc( thesweet.current[thexxi.current]['productproducer']);
//------------------------------------------------------------
      console.log("sweet_obj[xxi]['productimage']");
      console.log(sweet_obj[thexxi.current]['productimage']);
      theimage.current= sweet_obj[thexxi.current]['productimage'];
      setustttheimage(sweet_obj[thexxi.current]['productimage']);
     
  }
    
  React.useEffect(() => {    


//cont_vidRef.current.style.position="absolute";

//------------------------------------------------------
//======================================================
if ( (thesweet.current.length > 0)   && (nodev !==null)  )
{
    vidRef.current?.load();
     vidRef.current.currentTime = 0;
  }
    console.log('current?.load()');
  }, [srrc]);
  //--------------------------------------------------
  var catitem="";
  catitem= cookies.get('catitem');

  var paramscat=catitem.split(',')[1] ;
  var paramsi=catitem.split(',')[0] ;
 //--------------------------------------------------
 
useEffect(() => {
// ---------      video setting ---------------------

  //----------------------------


console.log("paramscat"); console.log(paramscat );
console.log("paramsi");  console.log(paramsi  );

var xxi=0; xxi=Number(paramsi); xxi=xxi-1;
console.log("xxi");  console.log(xxi  );
var xxc=0; xxc=Number(params.cat);
theparamscat.current=paramscat;   
thexxi.current=xxi;
    //-------------------------
     sweets();
    //------------------------
}, []);
/*
const product = [{
  name: null,  code: null,  qty: null,  pricebeforedis:null,
  priceafterdis:null,  discount:null,  expire:null,  note: null,
  user:null,  pass:null,  points:null,  mobile:null,  address:null,
  location:null,  branch:null, 
 }];  
 */
 const addToCart0 =(e)=>
 {
   for (let yy=0;yy< Number(qty);yy++)
   {
   /*
   product.discount =  thesweet.current[thexxi.current]['productdiscount'];  
   product.name =  thesweet.current[thexxi.current]['productname'];  
   product.pricebeforedis= thesweet.current[thexxi.current]['productprice'];
   product.priceafterdis=net_price.current;
   product.code =thesweet.current[thexxi.current]['productcode'];
   */
   firstContext.basketdata_name.push( thesweet.current[thexxi.current]['productname']);
   firstContext.basketdata_code.push(thesweet.current[thexxi.current]['productcode']);
    
   }
   console.log("sweet_details_firstContext.basketdata_code"); 
   console.log(firstContext.basketdata_code);
 }
 const addToCart =(e)=>
 {
   ////********       price   calc          */

   firstContext.basketdata_priceafterdis.push(net_price.current);

  ////********       qty     calc        */
  var ind=firstContext.basketdata_code.indexOf(thesweet.current[thexxi.current]['productcode']);
  if ( ind >=0)
  {
   var prev_qty=firstContext.basketdata_qty[ind]  ;
   var new_qty=prev_qty+Number(qty);
   firstContext.basketdata_qty[ind]=new_qty;
  }
  else
  {
    firstContext.basketdata_name.push( thesweet.current[thexxi.current]['productname']);
    firstContext.basketdata_code.push(thesweet.current[thexxi.current]['productcode']);
    firstContext.basketdata_discount.push(thesweet.current[thexxi.current]['productdiscount']);
    firstContext.basketdata_pricebeforedis.push(thesweet.current[thexxi.current]['productprice']);
    firstContext.basketdata_qty.push(Number(qty));
  }
   
  alert("تم إضافة الصنف");  
   
   console.log("sweet_details_firstContext.basketdata_name"); 
   console.log(firstContext.basketdata_name);
   console.log("sweet_details_firstContext.basketdata_code"); 
   console.log(firstContext.basketdata_code);
   console.log("sweet_details_firstContext.basketdata_discount"); 
   console.log(firstContext.basketdata_discount);
   console.log("sweet_details_firstContext.basketdata_pricebeforedis"); 
   console.log(firstContext.basketdata_pricebeforedis);
   console.log("sweet_details_firstContext.basketdata_priceafterdis"); 
   console.log(firstContext.basketdata_priceafterdis);
   console.log("sweet_details_firstContext.basketdata_qty"); 
   console.log(firstContext.basketdata_qty);
 }
    
  return (
    <>
      <React.Fragment>
         <AboveNavbar   />
      </React.Fragment>

   
      <React.Fragment>
         <Navbar   />
      </React.Fragment>
 
  { thesweet.current.length > 0  &&  
     <div style={{width:'100%',height:'auto', margin:'20px',display:'flex',justifyContent:'center'}} key={"mm"} > 
    
          <Card key={"mmc"}  
          style={{width:'70%',height:'auto', margin:'20px',
                  display:'flex',justifyContent:'center',alignItems:'center'   }}  >

          <div ref={cont_vidRef}  >
          {
          type_of_media.current &&  <video id="vid1"
            ref={vidRef}
            autoPlay={true}
            controls
            >

              <source ref={xsrrc} src={srrc} type="video/mp4" />

              Your browser does not support the video tag.
            </video>
          }

          {
          !type_of_media.current &&
          <div style={{width:'100%',height:'auto', margin:'0px',display:'flex',justifyContent:'center'}} key={"mm"} > 
          
          <img src={srrc} style={{width:'100%',height:'100%'}} ></img>
           </div>
          }


            </div>    
          {/* <Card.Img variant="top" 
          src={thesweet.current[thexxi.current] ['productimage']}  
          style={{width: '100%',height: 'auto'}} 
          /> */}
          <Card.Body>
          <Card.Title>{thesweet.current[thexxi.current]['productname']  } </Card.Title>
          {/* <Badge badgeContent={4} color="secondary">
            <MailIcon color="action" />
          </Badge> */}
          <Card.Text>  
           {  has_discount.current  &&  
           <p className='discount'> <s>{ `${thesweet.current[thexxi.current]['productprice']}`}  </s> جنيه</p>
           }
           {  has_discount.current  &&  
           <p className='price' >{ `${net_price.current}`}  {'جنيه '} </p>
           }
           { ! has_discount.current  &&  
           <p className='price'>  { `${thesweet.current[thexxi.current]['productprice']}`}    جنيه</p>
           }
           

         </Card.Text>

           
          
     
        <Box style={{width:'100%',display:'flex',justifyContent:'center'}}>
            <Typography variant="h5" >
          &nbsp;&nbsp;  من فضلك حدد الكمية  &nbsp;&nbsp; 
          </Typography>
        </Box>

        <Box style={{width:'100%',display:'flex',justifyContent:'center'}}>

          <IconButton 
           onClick={() => { if (qty>1)   { setqty(qty-1);} }}
          aria-label="remove" color ="primary">
          <RemoveIcon />
          </IconButton>

           <label   style={{fontSize:'25px',marginRight:'5px',marginLeft:'5px'}}>
             {qty}
            </label>
         
          <IconButton 
           onClick={() => {//alert(qty.current); 
            setqty(qty+1);
          }}
          aria-label="add" color ="primary">
          <AddIcon />
          </IconButton>

          
          </Box>
        

          <Button variant="primary" 
          onClick={() => addToCart(thesweet.current[thexxi.current]['productcode'] )}

          >   إضافة لسلة المشتريات</Button>
          </Card.Body>

          </Card> 

   </div>
  }
 

    </>
  );
};
 
export default Sweetdetails;

