import React,{useEffect,useState,useRef} from "react";
 
import {FaBars,FaTimes,FaSearch,FaShoppingCart,FaUser,FaRegListAlt} from "react-icons/fa" 

import PhoneIcon from '@mui/icons-material/Phone';
import InfoIcon from '@mui/icons-material/Info';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';

import '../App.css';
import Cookies from 'universal-cookie';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import { useNavigate } from "react-router-dom";
import { useStopwatch } from 'react-timer-hook';
import { ThefirstContext } from "../ThefirstContext";

import { Contactus } from "../Contactus";

import { Aboutus } from "../Aboutus";

import { location } from "../Location";




export default function Navbar(props) {
  
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });

  const firstContext = React.useContext(ThefirstContext);


  const persons = [{
    name: 'John Doe',
    age: 30,
    address: {
     street: '123 Main St',
     city: 'Anytown',
     state: 'Anystate'
    },
   
   }];

useEffect(()=>
{
//alert (seconds);

totalbasket.current = firstContext.basketdata_code.length; // totalbasket.current + 1;
 
},[seconds]);  

const totalbasket= useRef(0);

const navigation = useNavigate();    
const navRef=useRef();

const showNavbar=()=>{
  navRef.current.classList.toggle("responsive_nav");
}  
const both=()=>{
 const cookies = new Cookies();
 cookies.set('amosmobility1ur', 'n', { path: '/' });
 window.location.href('https://www.google.com');  //  'http://amos.egyptairme.com:5083/wlogin.aspx'
}  

 
const Aboutus=()=>{
  

  navigation("/Aboutus",   {   });   
 }  
 const Location=()=>{
  

  navigation("/Location",   {   });   
 }  
 const Contactus=()=>{
  

  navigation("/Contactus",   {   });   
 }  

 const Home=()=>{
  

  navigation("/",   {   });   
 }  
 
//    <a href="/#" >About </a>

  return (
    <header>
        <div style={{display:'flex',justifyContent:'left',justifyItems:'center',alignItems:'center',alignContent:'center'}}>
     <p className="logoname" onClick={()=>
      {
       //  alert('clicked');
        navigation("/" );   
           
        }}>Home</p>
     <img src="logo.png" width="100px" alt="EgyptAir" />
     

     <p className="thep"  onClick={()=>
      {
       //  alert('clicked');
        navigation("/Basket" );   
           
        }}> 
          <Badge badgeContent={totalbasket.current} color="secondary">
            <LocalGroceryStoreIcon   sx={{ fontSize: 30 }} color={'white'} />
          </Badge>
        
        </p> 
    </div>

     <nav ref={navRef}>
      
        {/* <div>
          <input className="srch" placeholder="Search..."/>
        </div>
        <button className="srchbtn" onClick={showNavbar}>
            <FaSearch size={25} color={'white'} />
        </button>
         */}
        &nbsp;
        &nbsp;
        
        <p className="thep"  onClick={Location}>  Address</p>
        <PersonPinCircleIcon size={25} color={'white'} />
        &nbsp; 
        &nbsp;
        &nbsp;
        
        <p className="thep"  onClick={Contactus}>Contact US</p>
        <PhoneIcon size={25} color={'white'} />
        &nbsp;  
        &nbsp;
        &nbsp;
        
        <p className="thep"  onClick={Aboutus}>About US</p>
        <InfoIcon size={25} color={'white'} />
        &nbsp; 

        
        
        <button className="nav-btn nav-close-btn" onClick={Aboutus}>
            <FaTimes />
        </button>
     </nav>

     <button className="nav-btn" onClick={showNavbar}>
        <FaBars />       
     </button>

    </header>
  );
}
