import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter  , Routes, Route , useParams   } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import {Button} from 'react-bootstrap';
import {Card} from 'react-bootstrap';
import {CardGroup} from 'react-bootstrap';
//import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';

import GoogleMapReact     from 'google-map-react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import GoogleMap  from 'google-maps-react-markers'

import { blue, blueGrey, grey } from '@mui/material/colors';
import Cookies from 'universal-cookie';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Buttonm from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import Badge from '@mui/material/Badge';
import Modal from '@mui/material/Modal';
import MailIcon from '@mui/icons-material/Mail';
import Chip from '@mui/material/Chip';
import ShoppingBasketTwoToneIcon from '@mui/icons-material/ShoppingBasketTwoTone';

import Typography from '@mui/material/Typography';
import Table from 'react-bootstrap/Table';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import { useStopwatch } from 'react-timer-hook';
import Navbar from './components/Navbar'

import AboveNavbar from './components/AboveNavbar'
import { ThefirstContext } from "./ThefirstContext";
import './base.css';
import { Label } from '@mui/icons-material';


const Checkout = () => {

  const firstContext = React.useContext(ThefirstContext);

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });

  
   var product = [{
    name: null,  code: null,  qty: null,  pricebeforedis:null,
    priceafterdis:null,  discount:null,  expire:null,  note: null,
    user:null,  pass:null,  points:null,  mobile:null,  address:null,
    location:null,  branch:null, 
   }];

   var product_code=[];

  const pro_code_arr=useRef([]);const pro_name_arr=useRef([]);const pro_qty_arr=useRef([]);
   
  const ctbody=useRef([]); const inside_body=useRef([]); 
  const [ssinside_body,setinside_body]=useState([]);
  const [body,setbody]=useState([]);
  
  const navigation = useNavigate();
 
 
  const cookies = new Cookies();
   
  
  const [qtynm,setqtynm]=useState(firstContext.basketdata_qty);
  const [items_total_price,setitems_total_price]=useState(0);
  const [alltotlalmoney,setalltotlalmoney]=useState(0);


  const [table,settable]=useState(false);
  const  table_number =useRef(0);

  let  tableposition= cookies.get('tableposition');
  console.log("tableposition");
  console.log(tableposition);
//   tableposition

  const [marlat,setmarlat]=useState (30.044456481933594); 
  const [marlng,setmarlng]=useState (31.2357120513916); 
  
  const [currlat,setcurrlat]=useState (0); 
  const [currlong,setcurrlong]=useState(0); 
  const [location, setLocation] = useState({
    latitude: 30.044456481933594,
    longitude: 31.2357120513916,
    latitudeDelta: 0.02,
    longitudeDelta: 0.02,
  });

  const  items_total_price_r =useRef(0);

  const  url=useRef('none');
  const  orderdata=useRef('');
  const  the_name =useRef();
  const  the_mobile =useRef();
  const  the_address =useRef();
//  

  
  const ppproduct = [{
    name: null,  code: null,  qty: null,  pricebeforedis:null,
    priceafterdis:null,  discount:null,  expire:null,  note: null,
    user:null,  pass:null,  points:null,  mobile:null,  address:null,
    location:null,  branch:null, 
   }];

 
useEffect(() => {

  //             //      
    if ( tableposition === undefined ) 
      {
         settable (false);
         console.log("no table");
        // table_number.current= ;
      }
      else
      {
        console.log(" table exist ");
        console.log(  tableposition);
        settable (true);
        table_number.current= tableposition ;
      }

 //     //   
  console.log("inside_basket_firstContext.basketdata_code");
  console.log(firstContext.basketdata_code);
  //-------------------------------------  
 
  //--------------------------------------------------
  inside_body.current=[];
  items_total_price_r.current=0;
  for (let u=0; u < firstContext.basketdata_code.length ; u++)
  {
    items_total_price_r.current=items_total_price_r.current+
    (firstContext.basketdata_priceafterdis[u]*firstContext.basketdata_qty[u]);

    inside_body.current.push(            
      <tr key={"r"+u} >
      <th  key={"h"+u} scope="row">{u+1}</th>
      <td key={"d1"+u}>{firstContext.basketdata_name[u]}</td>
      <td key={"d2"+u}>
      <Box  key={"bxd2"+u} style={{width:'100%',display:'flex',justifyContent:'center'}}>

       
      <label   key={"labl"+u} style={{fontSize:'25px',marginRight:'5px',marginLeft:'5px'}}    >
      
      { qtynm[u]}
       {/* {firstContext.basketdata_qty[u]} */}
      </label> 

      
      </Box>

      </td>
      <td key={"d3"+u}>
       { 
        ( Number(firstContext.basketdata_priceafterdis[u])*
         Number(firstContext.basketdata_qty[u]) ).toFixed(2)
         }
      
      </td>
      
      </tr>
            );
  }
var temp1=items_total_price_r.current.toFixed(2);
items_total_price_r.current=Number(temp1);
setitems_total_price(items_total_price_r.current);
setalltotlalmoney(25+items_total_price_r.current);
setinside_body(inside_body.current);
ctbody.current=[];

ctbody.current.push (<tbody key="bdy">{inside_body.current}</tbody>);

setbody(ctbody.current);
 
}, [] );  /// seconds firstContext.basketdata_qty
////-----------------------------------------------------------
////--------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #f9f',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

////-----------------------------------------------------------
////-------------------------------------------------------------

const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    firstContext. person = [];
    firstContext.product = [];
    firstContext.basketdata = [];
    firstContext.basketdata_name = [];
    firstContext.basketdata_code = [];
    firstContext.basketdata_qty = [];
    firstContext.basketdata_discount = [];
    firstContext.basketdata_pricebeforedis = []; 
    firstContext.basketdata_priceafterdis = []; 
    cookies.remove('tableposition');
    navigation("/");
  }; 
 

  var sweets=async()=>{
    //-----------------------------------------------------
 
    const userLogsInOptions = (  ) => {

      console.log("orderdata");
      console.log( orderdata.current );
      

      const formData = new URLSearchParams();

      formData.append('order', orderdata.current );
      return (
        {
          method: 'POST',
          headers: {
            // "Content-Type": "application/json; charset=utf-8",
            "Content-Type": "application/x-www-form-urlencoded",
        },
          body: formData.toString(),
        json: true,
      }
      );
    };
//----------------------------------------------------------------
 const response = await fetch(process.env.REACT_APP_BASE_URL+'/api/Product/receiveorder',
 userLogsInOptions() )
 const responseJson = await response.json();

    //---------------------------------------------------
    //  var   res0 = await axios.get(process.env.REACT_APP_BASE_URL+'/api/Product/selectsweet');
    console.log("responseJson");
    console.log(responseJson);
    console.log(responseJson[0].val);

    if (responseJson[0].val.indexOf("ok") >= 0)
    {
      setOpen(true);
      
    }   
}


const placeorder =( )=>
{
 // alert( the_name.current.value ); 
 if (table==true)
  {
    console.log("table"   );
    console.log(table_number  );
     
    //** */
    for (let y=0;y<firstContext.basketdata_code.length;y++)
    {
    orderdata.current=orderdata.current+","+
    firstContext.basketdata_code[y]+","+
    firstContext.basketdata_discount[y]+","+
    firstContext.basketdata_pricebeforedis[y]+","+
    firstContext.basketdata_priceafterdis[y]+","+
    firstContext.basketdata_qty[y]+";";
    
    }
    orderdata.current=orderdata.current+"*"+
                      " " +"*"+
                      " " +"*"+
                      " " +"*"+
                      " " +"*"+
                      " " +"*"+table_number.current ;
       
  }
  else
  {
  console.log(the_name.current.value   );
  console.log(the_mobile.current.value   );
  console.log(the_address.current.value   );

  //** */
  for (let y=0;y<firstContext.basketdata_code.length;y++)
  {
  orderdata.current=orderdata.current+","+
  firstContext.basketdata_code[y]+","+
  firstContext.basketdata_discount[y]+","+
  firstContext.basketdata_pricebeforedis[y]+","+
  firstContext.basketdata_priceafterdis[y]+","+
  firstContext.basketdata_qty[y]+";";
  
  }
  orderdata.current=orderdata.current+"*"+
                    the_name.current.value +"*"+
                    the_mobile.current.value +"*"+
                    the_address.current.value +"*"+
                    marlat +"*"+
                    marlng +"*"+table_number.current ;
                    
}
  //********** */

  sweets();
  
}
  
const AnyReactComponent = ({ text,img }) =>
 <div style={{display:'flex',flexDirection:'row'}}>{text} 
 <img style={{width:'50px',height:'50px'}} src={img} ></img></div>;

const defaultProps = {
  center: {
    lat: 30.044456481933594,
    lng: 31.2357120513916
  },
  zoom: 16
};

const handleApiLoaded = (map, maps) => {
  // use map and maps objects
};


  return (
   
    <> 
    <ThemeProvider dir="rtl"> 
      <React.Fragment>
         <AboveNavbar   />
      </React.Fragment>

   
      <React.Fragment>
         <Navbar   />
      </React.Fragment>
 
   
     <div style={{width:'100%',height:'auto', margin:'0px',display:'flex',justifyContent:'center'}} key={"chip1mm"} > 
      
          <Card key={"mmc"}  style={{width:'100%',height:'auto', margin:'0px',}}  >

           <Card.Body  style={{display:'flex',justifyContent:'center',flexDirection:'column'}}>
          <Card.Title  style={{display:'flex',justifyContent:'center'}}>CheckOut</Card.Title>
     
          <Card.Text style={{display:'flex',justifyContent:'center'}}>  
           Details

          </Card.Text>

          <Table key="t1" striped bordered hover variant="dark"  dir="rtl" responsive>
          <thead style={{  textAlign:'center'}} key="th1">
            <tr key="trr1">
              <th key="th11" scope="col">#</th>
              <th key="th12"  scope="col">الصنف</th>
              <th key="th13"  scope="col">الكمية</th>
              <th key="th14"  scope="col">السعر</th>
              
            </tr>
          </thead>
       
          {body}
          </Table>

          <div style={{width:'100%',height:'auto', marginBottom:'30px',display:'flex',justifyContent:'center'}} key={"mm1"} > 
     
          <Chip color="success" 
          //  variant="outlined" 
          style={{ direction :'rtl',}}
          sx={{
            width:'80%',
            height: 'auto',
            '& .MuiChip-label': {
              display: 'block',
              whiteSpace: 'normal',
              direction :'rtl',
            },
          }}
           icon={<ShoppingBasketTwoToneIcon />} 

          label={(
            <div  style={{fontSize:'20px', fontWeight :'bold',}}>
              <p> إجمالي الأصناف   &#160;&#160;{items_total_price}</p>
              <p> رسوم التوصيل &#160;&#160;{25}</p>
              <p> الإجمالي &#160;&#160;{alltotlalmoney}</p>
            </div>
          )}
                  
          />
          
          </div>
{ !table  &&
 <>
          <div style={{width:'100%',height:'auto', marginBottom:'30px',display:'flex',justifyContent:'center'}} key={"mm2"} > 
            
          <InputGroup className="mb-3"  style={{width:'80%'}}>
           <Form.Control dir='rtl'
            placeholder=" الإسم"
            aria-label=" "
            aria-describedby="basic-addon2"
            ref={the_name}
           />
           <InputGroup.Text  id="name">إسم العميل</InputGroup.Text>
           </InputGroup>
           </div>

           <div style={{width:'100%',height:'auto', marginBottom:'30px',display:'flex',justifyContent:'center'}} key={"mm3"} > 
          
           <InputGroup className="mb-3"  style={{width:'80%'}}>
           <Form.Control dir='rtl'
            placeholder=" رقم التليفون"
            aria-label=" "
            aria-describedby="basic-addon2"
            ref={the_mobile}
           />
           <InputGroup.Text id="mobile"> رقم التليفون  </InputGroup.Text>
           </InputGroup>
           </div>

           <div style={{width:'100%',height:'auto', marginBottom:'30px',display:'flex',justifyContent:'center'}} key={"mm4"} > 
          
           <InputGroup className="mb-3"  style={{width:'80%'}}>
           <Form.Control dir='rtl'
            placeholder=" العنوان"
            aria-label=" "
            aria-describedby="basic-addon2"
            ref={the_address}
           />
           <InputGroup.Text id="address">العنوان</InputGroup.Text>
           </InputGroup>
           
          </div>

          <div style={{ height: '400px',width: '100%',display:'flex',justifyContent:'center',margin:'20px' }} >
          <div style={{ height: '400px',
                        width: '400px',
                        display:'flex',
                       justifyContent:'center',
                       }} 
          
          key={"mm6"} > 
          
            <GoogleMapReact 
           
            bootstrapURLKeys={{ key: "AIzaSyDyw35Z5Qw1Xb_oACrQ-5FDjV7On7uRM24" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            showsUserLocation={true} 
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            mapContainerStyle={{
              margin: "auto",
              width: "50%",
              height: "50%",
              display:'flex',
              justifyContent:'center',
            }}
            region={location} 
            onDragEnd={(event) => {
               url.current = event.mapUrl   ;
               console.log("url.current");
               console.log(url.current);

               var tampll1=url.current.split('?')[1];
               var tampll2=tampll1.split('&')[0];
               var tampll3=tampll2.split('=')[1];
               var tamplat=tampll3.split(',')[0];
               var tamplng=tampll3.split(',')[1];
               
               console.log("tamplat");
               console.log(tamplat);
               console.log("tamplng");
               console.log(tamplng);

               setmarlat(tamplat);
               setmarlng(tamplng);
              
            }}
            onDrag={(event) => {
              console.log("drag_event");
              console.log(event );
              
              url.current = event.mapUrl   ;
              console.log("dragging");
              console.log("url.current");
              console.log(url.current);

              var tampll1=url.current.split('?')[1];
              var tampll2=tampll1.split('&')[0];
              var tampll3=tampll2.split('=')[1];
              var tamplat=tampll3.split(',')[0];
              var tamplng=tampll3.split(',')[1];
              
              console.log("tamplat");
              console.log(tamplat);
              console.log("tamplng");
              console.log(tamplng);

              setmarlat(tamplat);
              setmarlng(tamplng);
             
           }}
           onCenterChanged={(event) => {
            url.current = event.mapUrl   ;
            console.log("change");
            console.log("url.current");
            console.log(url.current);

            var tampll1=url.current.split('?')[1];
            var tampll2=tampll1.split('&')[0];
            var tampll3=tampll2.split('=')[1];
            var tamplat=tampll3.split(',')[0];
            var tamplng=tampll3.split(',')[1];
            
            console.log("tamplat");
            console.log(tamplat);
            console.log("tamplng");
            console.log(tamplng);

            setmarlat(tamplat);
            setmarlng(tamplng);
           
         }}
            onRegionChange={(region)=>{
            console.log("region");console.log(region);
            setcurrlat(region.latitude);
            setcurrlong(region.longitude);
            // AIzaSyDyw35Z5Qw1Xb_oACrQ-5FDjV7On7uRM24
            }}
            >
 
                    <AnyReactComponent 
                    style={{ width:'150px', height: '150px',backgroundcolor:'red'}} 
                    lat={marlat}
                    lng={marlng}
                    text="."
                    img={ './customloc1.png' }   
                      key={'id_' + "1"}

                    draggable={true}
                    />
                


            </GoogleMapReact>
          </div>
          </div>
  </>
}

{
  table  &&
  <div style={{width:'100%',height:'auto', marginBottom:'30px',display:'flex',justifyContent:'center'}} key={"table2"} > 
            
  <p> Table Number :  { table_number.current}</p>
  </div>
}
          <div style={{width:'100%',height:'auto', marginBottom:'30px',display:'flex',justifyContent:'center'}} key={"mm5"} > 
          
          <Button variant="primary"  style={{width:'70%',display:'flex',justifyContent:'center'}}
          onClick={() => {placeorder();}}

          >  تأكيد الأوردر
          </Button>
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="child-modal-title"
    aria-describedby="child-modal-description"
  >
        <Box sx={{ ...style, width: 300,direction:'rtl',display:'flex',justifyContent:'center' ,flexDirection:'column'}}>
         <div style={{width:'100%'}}>
          <h2 style={{textAlign:'center',fontWeight:'bold'}} id="child-modal-title">تم الطلب بنجاح</h2>
          </div>
          <div style={{width:'100%'}}>
          <p style={{textAlign:'right'}}  id="child-modal-description">
          تم استلام طلبكم و جاري تنفيذه
          </p>
          </div>
          <Button onClick={handleClose}>إغلاق</Button>
        </Box>
  </Modal>
          </div>
        

          </Card.Body>

          </Card> 


     </div>


     
       { }
     </ThemeProvider>
    </>
  );
};
 
export default Checkout;

