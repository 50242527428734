import React,{useEffect,useState,useRef} from "react";
import "./base.css";
import "./lightbox.css";
import cors from 'cors'
import Cookies from 'universal-cookie';
import {getairlines} from './functions1';
import {get_outstations} from './functions1';
import axios from 'axios'
// npm run build

import { useNavigate } from "react-router-dom";
 
import raw from './mainpath5.txt';
import raw6 from './mainpath6.txt';
import raw3 from './mainpath3.txt';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import {Button} from 'react-bootstrap';
import {Card} from 'react-bootstrap';
import {CardGroup} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Wanted01customized from './wanted01customized';
import { ThefirstContext } from "./ThefirstContext";

//-----------------------------------------------------

export default function Wanted01(props) {
//---------------------------------------------------------------
const cookies = new Cookies();
  const p2=()=>{
    var url =  'https://google.com';
    window.open(url, '_blank'); 
  }  

  const navigation = useNavigate();
  //var cat=2;
  const theimage=useRef(process.env.REACT_APP_BASE_URL+'/images/products/giphy.gif');
  const [ustttheimage,setustttheimage]=useState(process.env.REACT_APP_BASE_URL+'/images/products/giphy.gif');
  
  const  [ustwo_obj,setustwo_obj]=useState([]);
    
  const  two_obj=useRef([]);
    
  const firstContext = React.useContext(ThefirstContext);

  const persons = [{
    name: 'John Doe',
    age: 30,
    address: {
     street: '123 Main St',
     city: 'Anytown',
     state: 'Anystate'
    },
   
   }];

//  firstContext.pressedbtn==="1"  
//  if ( ( firstContext.thelessondetails===null) || ( firstContext.thelessondetails===undefined)    )
//  {firstContext.thelessondetails=props.lessondetails;}

  //  https://commercex.internet-online.net/api/Product/selectsweetbestseller
  const bestseller_arr=useRef([]);
  
  useEffect(() => {

  let  the_cookie= cookies.get('tableposition');
  console.log("thecookie");
  console.log(the_cookie);
  
   // firstContext.person.pop();firstContext.person.push(persons);
   //console.log("firstContext.person"); console.log(firstContext.person);
   //-------------------------
    sweets();
   //------------------------
 }, []);
 var sweets=async()=>{
   // var   res0 = await axios.get(process.env.REACT_APP_BASE_URL+'/api/Product/selectsweet');
    console.log("thesweet");
      //------------------------------------------------
      const userLogsInOptions = (xx ) => {
 
        console.log("thexx");
        console.log(xx);
 
        const formData = new URLSearchParams();
 
        formData.append('productcategory', xx );
        return (
          {
            method: 'POST',
            headers: {
              // "Content-Type": "application/json; charset=utf-8",
              "Content-Type": "application/x-www-form-urlencoded",
          },
            body: formData.toString(),
          json: true,
        }
        );
      };
      
 //-------------------------------------------------
 const response = await fetch(process.env.REACT_APP_BASE_URL+'/api/Product/selectsweetbestseller',
 userLogsInOptions(" ") )
 const responseJson = await response.json();
  
 //------------------------------------------------------
 bestseller_arr.current=[];
 
 bestseller_arr.current=  responseJson ;
    console.log(bestseller_arr.current);  // select_sweet_by_category   productcategory
    console.log("theimage.current");
    var sweet_obj=bestseller_arr.current;
     
   
    const  arrayDataItems = bestseller_arr.current.map((item,index) =>
    {
     return(
      <Card  key={index}>
      <Card.Img variant="top" src={item['productimage']} />
      <Card.Body>
        <Card.Title>{bestseller_arr.current[index]['productname'] }</Card.Title>
        <Card.Text>
        {bestseller_arr.current[index]['productdescription']}
        </Card.Text>
        <Card.Text>
          {bestseller_arr.current[index]['productprice']}
        </Card.Text>
        <Button variant="primary" onClick={()=>handle_best_seller(index)}>show product</Button>
      </Card.Body>
    </Card>    
    
      )
 
    } );
     two_obj.current= arrayDataItems ; 
     setustwo_obj(arrayDataItems); 

 }
 var tcx=0;
  {/*     
    var catitem = item['product_inside_category_order'] +","+item['productcategory']     ;
    cookies.set('catitem', catitem, { path: '/' });navigation("/Sweetdetail", { index:  item['product_inside_category_order']   ,cat: item['productcategory']  });   
            */}
 const handle_best_seller =(e)=> 
 {
  var catitem = bestseller_arr.current[e]['product_inside_category_order'] +
           ","+bestseller_arr.current[e]['productcategory']     ;
  cookies.set('catitem', catitem, { path: '/' });
  navigation("/Sweetdetail", 
            { index:  bestseller_arr.current['product_inside_category_order'] ,
              cat: bestseller_arr.current['productcategory']  });   
    
  // alert (bestseller_arr.current[e]['productprice']);

 } 
// C3A241
  return (
    <>
     <br></br>
    <Carousel className="carousel-style"
     autoPlay='true'
     infiniteLoop='true'
     axis='horizontal'
    //  showThumbs='false'
     thumbWidth='70px'
     >
    <div>
        {/* <img src="https://lab-x11.internet-online.net/files/L1s2.jpg" /> */}
        <img style={{width: '100%', }} src={process.env.REACT_APP_BASE_URL+"/images/1.jpeg"} />
        {/* <p className="legend">Legend 1</p> */}
    </div>
    <div>
        <img  style={{width: '100%', }} src={process.env.REACT_APP_BASE_URL+"/images/2.jpeg"} />
        {/* <p className="legend" onClick={p2}>Click here For more details</p> */}
    </div>
    <div> 
        <img  style={{width: '100%', }} src={process.env.REACT_APP_BASE_URL+"/images/3.jpeg"}  />
        {/* <p className="legend">Legend 3</p> */}
    </div>
    
    <div> 
        <img  style={{width: '100%', }} src={process.env.REACT_APP_BASE_URL+"/images/4.jpeg"} />
        {/* <p className="legend">Legend 3</p> */}
    </div>
    </Carousel>

    <br></br>
  
    {/* <div  className="rrow">   
    <Card  className="one_cat">
      <Card.Img variant="top" src="/purified/cat1.png"  style={{width: '100%',height: 'auto'}} />
      <Card.Body>
        <Card.Title>خصومات</Card.Title>
        <Card.Text>
         عروض و خصومات
        </Card.Text>
        <Button variant="primary" 
        onClick={( )=>
          {
           tcx=2;  
          //  navigation("/Sweetcategory", {  "cx": cx  });
          cookies.set('merchantx1cat', tcx, { path: '/' });
 
          navigation('/Sweetcategorydis',{cx: tcx});
          }  
        }
        
        >اضغط هنا</Button> </Card.Body>
    </Card>  
    </div> */}

    <div  className="rrow">
    <Card className="one_cat">
      <Card.Img variant="top" 
        src={process.env.REACT_APP_BASE_URL+"/images/Appetizers.jpeg"}
        style={{width: '100%',height: 'auto'}} 
        onClick={( )=>
          {
           tcx=2;  
          //  navigation("/Sweetcategory", {  "cx": cx  });
          cookies.set('merchantx1cat', tcx, { path: '/' });
 
          navigation('/Sweetcategory',{cx: tcx});
          }  
        }
        />
      <Card.Body>
        <Card.Title>  Appetizers </Card.Title>
        
        <Button variant="primary" 
        onClick={( )=>
          {
           tcx=2;  
          //  navigation("/Sweetcategory", {  "cx": cx  });
          cookies.set('merchantx1cat', tcx, { path: '/' });
 
          navigation('/Sweetcategory',{cx: tcx});
          }  
        }
        
        >   Click here</Button>
      </Card.Body>
    </Card>   
    </div>
   
  
    <br></br>
    {/* <div  className="rrow"> 
    <p style={{fontSize:'28px',fontWeight:"bold"}}>Best Seller</p>
    </div>
    <br></br>
 
    <div className="d-flex flex-row flex-nowrap overflow-auto">  
     {ustwo_obj}
    </div> */}

{/** biryani here */}
    <br></br>
    <div className="rrow">
    <Card className="one_cat">
      <Card.Img variant="top"  src={process.env.REACT_APP_BASE_URL+"/images/biryani.jpeg"}
        style={{width: '100%',height: 'auto'}} 
        onClick={( )=>
          {
           tcx=3;  
          //  navigation("/Sweetcategory", {  "cx": cx  });
          cookies.set('merchantx1cat', tcx, { path: '/' });
 
          navigation('/Sweetcategory',{cx: tcx});
          }  
        } 
        />
      <Card.Body>
        <Card.Title>biryani</Card.Title>
        
        <Button variant="primary" 
        onClick={( )=>
          {
           tcx=3;  
          //  navigation("/Sweetcategory", {  "cx": cx  });
          cookies.set('merchantx1cat', tcx, { path: '/' });
 
          navigation('/Sweetcategory',{cx: tcx});
          }  
        }
        
        >   Click here</Button> </Card.Body>
    </Card> 
    </div>  
    <br></br>

{ /** chineese_dishes  here */}
 
 
    <br></br>
    <div className="rrow">
    <Card className="one_cat">
      <Card.Img variant="top"  src={process.env.REACT_APP_BASE_URL+"/images/chineese_dishes.jpeg"}
        style={{width: '100%',height: 'auto'}} 
        onClick={( )=>
          {
           tcx=4;  
          //  navigation("/Sweetcategory", {  "cx": cx  });
          cookies.set('merchantx1cat', tcx, { path: '/' });
 
          navigation('/Sweetcategory',{cx: tcx});
          }  
        } 
        />
      <Card.Body>
        <Card.Title>chineese_dishes</Card.Title>
        
        <Button variant="primary" 
        onClick={( )=>
          {
           tcx=4;  
          //  navigation("/Sweetcategory", {  "cx": cx  });
          cookies.set('merchantx1cat', tcx, { path: '/' });
 
          navigation('/Sweetcategory',{cx: tcx});
          }  
        }
        
        >   Click here</Button> </Card.Body>
    </Card> 
    </div>  
    <br></br>

    { /** indians_breads  here */}

 
    <br></br>
    <div className="rrow">
    <Card className="one_cat">
      <Card.Img variant="top"  src={process.env.REACT_APP_BASE_URL+"/images/indians_breads.jpeg"}
        style={{width: '100%',height: 'auto'}} 
        onClick={( )=>
          {
           tcx=5;  
          //  navigation("/Sweetcategory", {  "cx": cx  });
          cookies.set('merchantx1cat', tcx, { path: '/' });
 
          navigation('/Sweetcategory',{cx: tcx});
          }  
        } 
        />
      <Card.Body>
        <Card.Title>indians_breads</Card.Title>
        
        <Button variant="primary" 
        onClick={( )=>
          {
           tcx=5;  
          //  navigation("/Sweetcategory", {  "cx": cx  });
          cookies.set('merchantx1cat', tcx, { path: '/' });
 
          navigation('/Sweetcategory',{cx: tcx});
          }  
        }
        
        >   Click here</Button> </Card.Body>
    </Card> 
    </div>  
    <br></br>

    { /**  indians_dishes here */}

  
    <br></br>
    <div className="rrow">
    <Card className="one_cat">
      <Card.Img variant="top"  src={process.env.REACT_APP_BASE_URL+"/images/indians_dishes.jpeg"}
        style={{width: '100%',height: 'auto'}} 
        onClick={( )=>
          {
           tcx=6;  
          //  navigation("/Sweetcategory", {  "cx": cx  });
          cookies.set('merchantx1cat', tcx, { path: '/' });
 
          navigation('/Sweetcategory',{cx: tcx});
          }  
        } 
        />
      <Card.Body>
        <Card.Title>indians_dishes</Card.Title>
        
        <Button variant="primary" 
        onClick={( )=>
          {
           tcx=6;  
          //  navigation("/Sweetcategory", {  "cx": cx  });
          cookies.set('merchantx1cat', tcx, { path: '/' });
 
          navigation('/Sweetcategory',{cx: tcx});
          }  
        }
        
        >   Click here</Button> </Card.Body>
    </Card> 
    </div>  
    <br></br>

    { /**  meals here */}

  
    <br></br>
    <div className="rrow">
    <Card className="one_cat">
      <Card.Img variant="top"  src={process.env.REACT_APP_BASE_URL+"/images/meals.jpeg"}
        style={{width: '100%',height: 'auto'}} 
        onClick={( )=>
          {
           tcx=7;  
          //  navigation("/Sweetcategory", {  "cx": cx  });
          cookies.set('merchantx1cat', tcx, { path: '/' });
 
          navigation('/Sweetcategory',{cx: tcx});
          }  
        } 
        />
      <Card.Body>
        <Card.Title>meals</Card.Title>
        
        <Button variant="primary" 
        onClick={( )=>
          {
           tcx=7;  
          //  navigation("/Sweetcategory", {  "cx": cx  });
          cookies.set('merchantx1cat', tcx, { path: '/' });
 
          navigation('/Sweetcategory',{cx: tcx});
          }  
        }
        
        >   Click here</Button> </Card.Body>
    </Card> 
    </div>  
    <br></br>
    { /** salads  here */}

      
    <br></br>
    <div className="rrow">
    <Card className="one_cat">
      <Card.Img variant="top"  src={process.env.REACT_APP_BASE_URL+"/images/salads.jpeg"}
        style={{width: '100%',height: 'auto'}} 
        onClick={( )=>
          {
          tcx=8;  
          //  navigation("/Sweetcategory", {  "cx": cx  });
          cookies.set('merchantx1cat', tcx, { path: '/' });

          navigation('/Sweetcategory',{cx: tcx});
          }  
        } 
        />
      <Card.Body>
        <Card.Title>salads</Card.Title>
        
        <Button variant="primary" 
        onClick={( )=>
          {
          tcx=8;  
          //  navigation("/Sweetcategory", {  "cx": cx  });
          cookies.set('merchantx1cat', tcx, { path: '/' });

          navigation('/Sweetcategory',{cx: tcx});
          }  
        }
        
        >   Click here</Button> </Card.Body>
    </Card> 
    </div>  
    <br></br>

    { /** soups  here */}

  
    <br></br>
    <div className="rrow">
    <Card className="one_cat">
      <Card.Img variant="top"  src={process.env.REACT_APP_BASE_URL+"/images/soups.jpeg"}
        style={{width: '100%',height: 'auto'}} 
        onClick={( )=>
          {
           tcx=9;  
          //  navigation("/Sweetcategory", {  "cx": cx  });
          cookies.set('merchantx1cat', tcx, { path: '/' });
 
          navigation('/Sweetcategory',{cx: tcx});
          }  
        } 
        />
      <Card.Body>
        <Card.Title>soups</Card.Title>
        
        <Button variant="primary" 
        onClick={( )=>
          {
           tcx=9;  
          //  navigation("/Sweetcategory", {  "cx": cx  });
          cookies.set('merchantx1cat', tcx, { path: '/' });
 
          navigation('/Sweetcategory',{cx: tcx});
          }  
        }
        
        >   Click here</Button> </Card.Body>
    </Card> 
    </div>  
    <br></br>

    
 
  
{/**  Reserve here */}

    <br></br> 
     <div className="rrow">
     <Card className="one_cat">
      <Card.Img variant="top" src={process.env.REACT_APP_BASE_URL+"/images/reserve2.jpeg"}   style={{height:"100%"}}  />
      <Card.Body>
        <Card.Title>Reserve Table  </Card.Title>
        <Card.Text>
        Reserve Table Before Coming 
        </Card.Text>
        <Button variant="primary" 
        onClick={( )=>
          {
           tcx=6;  
          //  navigation("/Sweetcategory", {  "cx": cx  });
          cookies.set('merchantx1cat', tcx, { path: '/' });
 
          navigation('/Reserve',{cx: tcx});
          }  
        }
        
        >اضغط هنا</Button> </Card.Body>
    </Card>  
    </div>
    
     <br></br> 
 
     <br></br> 
       

    </>
  );
}