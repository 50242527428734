import {useRef} from "react";
import {FaBars,FaTimes,FaFacebook,FaTwitter,FaTiktok,FaInstagramSquare} from "react-icons/fa" 
import '../App.css';
import Cookies from 'universal-cookie';


export default function AboveNavbar(props) {
  
    
const navRef=useRef();

const showNavbar=()=>{
  navRef.current.classList.toggle("responsive_nav");
}  
const both=()=>{
 // navRef.current.classList.toggle("responsive_nav");
 // props.handleclick5();
 const cookies = new Cookies();
 cookies.set('amosmobility1ur', 'n', { path: '/' });
 window.location.replace('http://amos.egyptairme.com:5083/wlogin.aspx');  //  'http://amos.egyptairme.com:5083/wlogin.aspx'
}  

//    <a href="/#" >About </a>

  return (
  
     <> 
      <div className="above0_grid">
          <div className="above1_grid">
          
            <div className="grid-item">
                <p> </p>
            </div>

            <div className="grid-item">
                <p onClick={props.handleclick5}>      استمتع بخصومات و عروض مستمرة طوال أيام الأسبوع   </p>
            </div>

            <div className="grid-item" id="tobehidden">
            <a href="https://www.google.com"><FaFacebook/></a>
            <a href="https://www.msn.com"><FaTwitter/></a>
            <a href="https://www.yahoo.com"><FaInstagramSquare/></a>
            <a href="https://www.gsmarena.com"><FaTiktok/></a>
              
            

            </div>
          
          </div>
       </div> 
 
    </>
  );
}
    
