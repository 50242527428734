import React from "react";

const person_original = {
  name: 'John Doe',
  age: 30,
  address: {
   street: '123 Main St',
   city: 'Anytown',
   state: 'Anystate'
  },
  greet: function() {
   console.log(`Hello, my name is ${this.name}`);
  }
 };

 const person = {
  name: 'John Doe',
  age: 30,
  address: {
   street: '123 Main St',
   city: 'Anytown',
   state: 'Anystate'
  },
 
 };
 const product = {
  name: null,  code: null,  qty: null,  pricebeforedis:null,
  priceafterdis:null,  discount:null,  expire:null,  note: null,
  user:null,  pass:null,  points:null,  mobile:null,  address:null,
  location:null,  branch:null, 
 };

 const basketdata = {
  name: null,  code: null,  qty: null, };
 

export const ThefirstContext = React.createContext(
    { //toggleTheme: () => {},
      the_student_code   : null,
      thelessondetails   : null ,
      thelessondetails2   : null,
  
      lesson_available   : null,
      lesson_pdg    : null,
      lesson_pric    : null,
      lesson_pth    : null,
      lesson_purchased    : null,
      student_grade    : null,
      student_id    : null,
      student_subject    : null,
      subject_code    : null,
      pressedbtn    : null,
      person : [],
      product : [],
      basketdata : [],
      basketdata_name : [],
      basketdata_code : [],
      basketdata_qty : [],
      basketdata_discount : [], 
      basketdata_pricebeforedis : [], 
      basketdata_priceafterdis : [], 
    
    }
  );