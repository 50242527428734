import {useRef} from "react";
import {FaBars,FaTimes,FaFacebook,FaTwitter,FaTiktok,FaInstagramSquare} from "react-icons/fa" 
import '../App.css';
import '../Footer1.css';
import Cookies from 'universal-cookie';


export default function Footer1(props) {
  
    


  return (
  
     <> 
      <div className="footer1">
          <div className="footer1_1">
          
          <div className="footer1_s1">
              <p>خصم ١٠٪ و   نقاط هدية على كل باقات عالم توليفات البن</p>
          </div>

          <div className="footer1_s2">
          <a href="https://www.google.com"><FaFacebook/></a>
          <a href="https://www.msn.com"><FaTwitter/></a>
          <a href="https://www.yahoo.com"><FaInstagramSquare/></a>
          <a href="https://www.gsmarena.com"><FaTiktok/></a>
            
         

          </div>
         <hr></hr>  
          </div>
          <div className="footer1_2">
          
            <div className="footer1_s1">
            <p>خصم ١٠٪ و   نقاط هدية على كل باقات عالم توليفات البن</p>
          </div>

            <div className="footer1_s2">
            <a href="https://www.google.com"><FaFacebook/></a>
            <a href="https://www.msn.com"><FaTwitter/></a>
            <a href="https://www.yahoo.com"><FaInstagramSquare/></a>
            <a href="https://www.gsmarena.com"><FaTiktok/></a>
              
            </div>
            
            <hr></hr>  
          
          </div>
          <div className="footer1_3">
          
            <div className="footer1_s1">
            <p>خصم ١٠٪ و   نقاط هدية على كل باقات عالم توليفات البن</p>
          </div>

            <div className="footer1_s2">
            <a href="https://www.google.com"><FaFacebook/></a>
            <a href="https://www.msn.com"><FaTwitter/></a>
            <a href="https://www.yahoo.com"><FaInstagramSquare/></a>
            <a href="https://www.gsmarena.com"><FaTiktok/></a>
              
            </div>
            
            <hr></hr>  
          
          </div>
          
       </div> 
 
    </>
  );
}
    
