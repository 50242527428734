//  onPress={( )=>{   navigation.navigate("Sweetcategory", {    index  });  }  }

import React, {useLocation , useEffect, useRef, useState } from 'react';
import { BrowserRouter  , Routes, Route , useParams   } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import {Button} from 'react-bootstrap';
import {Card} from 'react-bootstrap';
import {CardGroup} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ErrorBoundary } from 'react-error-boundary';
import Cookies from 'universal-cookie';
import Navbar from './components/Navbar'

import AboveNavbar from './components/AboveNavbar'



function MyFallbackComponent({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

export default function Sweetcategorydis(props) {

  const cookies = new Cookies();  
  const itemxx=useRef(0);

  const navigation = useNavigate();
 
  const  two_obj=useRef([]);
  const  thecatxx=useRef(0);

  const net_price=useRef(0) ;
  const has_discount=useRef(false) ;
  
  
  const  [ustwo_obj,setustwo_obj]=useState([]);



    const theimage=useRef(process.env.REACT_APP_BASE_URL+'/images/products/giphy.gif');
    const [ustttheimage,setustttheimage]=useState(process.env.REACT_APP_BASE_URL+'/images/products/giphy.gif');

    const thesweet=useRef([]);// <any[]>  
  
    var sweets=async()=>{
      //-----------------------------------------------------
   
      const userLogsInOptions = (xx ) => {

        console.log("thexx");
        console.log(xx);
 
        const formData = new URLSearchParams();
 
        formData.append('productcategory', xx );
        return (
          {
            method: 'POST',
            headers: {
              // "Content-Type": "application/json; charset=utf-8",
              "Content-Type": "application/x-www-form-urlencoded",
          },
            body: formData.toString(),
          json: true,
        }
        );
      };
 //----------------------------------------------------------------
      
 //------  https://commercex.internet-online.net/api/Product/selectsweetsearch
 const response = await fetch(process.env.REACT_APP_BASE_URL+'/api/Product/selectsweetbydiscount',
 userLogsInOptions(thecatxx.current) )
 const responseJson = await response.json();
  
      //---------------------------------------------------
      //  var   res0 = await axios.get(process.env.REACT_APP_BASE_URL+'/api/Product/selectsweet');
        console.log("thesweet");
        thesweet.current=responseJson;//.data;
        console.log(thesweet.current);  // 

        console.log("theimage.current");
        var sweet_obj=thesweet.current; 
        var ggtwoobj=[];//   two_obj.current
        for (let cv=1;cv<thesweet.current.length+1;cv++)
        {      
          console.log(thesweet.current[cv-1]);
         // var sss; sss ? thesweet.current[cv-1]['productimage'] : sss="";
          var   sss ="";
          sss= thesweet.current[cv-1]['productimage'];
          sss=sss.toString().trim()   ;
          console.log("sss"); 
          console.log(sss);
//--------------------------- net_price  has_discount ---------------------------------

if ( thesweet.current[cv-1]['productdiscount'] !=='0' )
{  
  has_discount.current=true; 
  var pri=Number(  thesweet.current[cv-1]['productprice']  );
  var dis=Number(  thesweet.current[cv-1]['productdiscount']  );
  
  net_price.current =pri - (dis * pri /100) ;
}


//------------------------------------------------------------


          ggtwoobj.push(
         
          <Card className="one_cat" id={"ccv"+{cv}}   key={"ccv"+cv}  >
          <Card.Img variant="top" src={sss}  style={{width: '100%',height: 'auto'}} />
          <Card.Body>
            <Card.Title>{thesweet.current[cv-1]['productcode']  } </Card.Title>
            <Card.Text>
            {thesweet.current[cv-1]['productdescription']}
            </Card.Text>
            <Card.Text>  
           {  has_discount  &&  
           <p className='discountcat'> <s>{ `${thesweet.current[cv-1]['productprice']}`}  </s> جنيه</p>
           }
           {  has_discount  &&  
           <p className='pricecat' >{ `${net_price.current}`}  {'جنيه '} </p>
           }
           { ! has_discount  &&  
           <p className='pricecat'> <s>{ `${thesweet.current[cv-1]['productprice']}`}  </s> جنيه</p>
           }
           

         </Card.Text>
            <Button variant="primary" 
            onClick={() => handlecardclick(`${cv}`)}
            
            >اضغط هنا</Button>
          </Card.Body>
          </Card>   
         );
        };
        const  arrayDataItems = thesweet.current.map((item,index) =>
        {
          if ( thesweet.current[index]['productdiscount'] !=='0' )
            {  
              has_discount.current=true; 
              var pri=Number(  thesweet.current[index]['productprice']  );
              var dis=Number(  thesweet.current[index]['productdiscount']  );
              
              net_price.current =pri - (dis * pri /100) ;
            }
            else
            {
              has_discount.current=false; 
            }
           
         return(
         <div
            style={{width:'200px',height:'450px', margin:'20px',  gap: '30px',
            marginBottom: '30px',}}  key={index}  
            onClick={event => {
            var catitem = item['product_inside_category_order'] +","+
                          item['productcategory']     ;
            cookies.set('catitem', catitem, { path: '/' });
 
            navigation("/Sweetdetail", { index:  item['product_inside_category_order']   ,cat: item['productcategory']  });   
          
          }}
          
          > 
                  
         <Card key={index} >
        
         <Card.Img variant="top" src={item['productimage']}  style={{width: '100%',height: 'auto'}} />
          <Card.Body>
            <Card.Title>{thesweet.current[index]['productcode']  } </Card.Title>
            <Card.Text>
            {thesweet.current[index]['productdescription']}
            </Card.Text>


            <Card.Text>  
           {  has_discount.current  &&  
           <p className='discountcat'> <s>{ `${thesweet.current[index]['productprice']}`}  </s> جنيه</p>
           }
           {  has_discount.current  &&  
           <p className='pricecat' >{ `${net_price.current}`}  {'جنيه '} </p>
           }
           { ! has_discount.current  &&  
           <p className='pricecat'>  { `${thesweet.current[index]['productprice']}`}    جنيه</p>
           }
           

         </Card.Text>

            <Button variant="primary" 
            onClick={() => handlecardclick(`${index}`)}
            
            >للتفاصيل اضغط هنا </Button>
          </Card.Body>

         </Card> 
     
         </div>
          )

         } );
         two_obj.current= ggtwoobj ; 
         setustwo_obj(arrayDataItems); 
        // setustwo_obj(ggtwoobj); 
         
        // var image; image?sweet_obj[catxx]['productimage'] : image= "null";//thesweet.current[catxx]['productimage']

        // console.log(image); theimage.current= image;  setustttheimage(image);
       // 
       // setmainimage(1);
    }
      

    useEffect(() => {

      //---------------------------
    
      var merchantx1cat="";
      merchantx1cat= cookies.get('merchantx1cat');
       
      var catxx=0;
      catxx=Number(merchantx1cat);//.cx
      thecatxx.current=catxx;
      console.log("the xx");
      console.log( catxx );
       
      //-------------------------
       sweets();
      //------------------------
  }, []);
    

    const handlecardclick=( xx )=>
{
  console.log("Category: "+thecatxx.current+" item: "+xx);
 // alert(xx);
}

  return (
<ErrorBoundary
      FallbackComponent={MyFallbackComponent}
      onReset={() => {
        // reset the state of your app here
      }}
      resetKeys={['someKey']}
    >
      <React.Fragment>
         <AboveNavbar   />
      </React.Fragment>

   
      <React.Fragment>
         <Navbar   />
      </React.Fragment>
 

    <div style={{
      display:'flex',
      justifyContent:'center',
       marginTop: '100px' ,
        width:'100%',
        // height: '1000px',overflow:'scroll',
        
       flexWrap:'wrap',
     
        }}> 
    
    {ustwo_obj}
   
    </div>

    </ErrorBoundary>    
  );
};
 
