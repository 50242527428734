import React,{useEffect,useState} from "react";
import ReactDOM from 'react-dom/client';
import logo from './logo.svg';

import '../src/Styles/main.css'
import './App.css';
import './Above.css';

import Navbar from './components/Navbar'

import AboveNavbar from './components/AboveNavbar'
import Footer1 from './components/Footer1'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Dashboard from './Dashboard';
import Transit from "./Transit";
import Transitwd from "./Transitwd";
import PerformedWO from "./PerformedWO";
import Oncall from "./Oncall";
import Wanted01 from "./Wanted01";
import Login from "./components/login/Login";
import maindata from "./components/login/maindata";

import {Button, Row, Col, Toast} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import getToken_custom from './firebase.js';
//import onMessageListener from './onmessage.js';
import onMessageListener from './firebase.js';
import {Link, useNavigate } from "react-router-dom";
//import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
  


function Home() {


 
  const cookies = new Cookies();
 // cookies.set('myCat', 'Pacman', { path: '/' });
 // cookies.set('username', 'semsem', { path: '/' });
 // cookies.set('amosmobility1ur', 'Pacman', { path: '/' });



 
 
  return (
    
   <>
 


      <React.Fragment>
         {/* <AboveNavbar   /> */}
      </React.Fragment>

      <div className="App">
      <React.Fragment>
         <Navbar   />
      </React.Fragment>


     <Wanted01  />  
 

    </div>

      <React.Fragment>
          <Footer1/>
      </React.Fragment>

    </>

  );
}

export default Home;
