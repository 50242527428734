import React,{useEffect,useState} from "react";
import ReactDOM from 'react-dom/client';
import logo from './logo.svg';

import '../src/Styles/main.css'
import './App.css';
import './Above.css';

import Reserve from  "./Reserve.js";
import Navbar from './components/Navbar'

import AboveNavbar from './components/AboveNavbar'
import Footer1 from './components/Footer1'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Dashboard from './Dashboard';
import Transit from "./Transit"; 
import Transitwd from "./Transitwd";
import PerformedWO from "./PerformedWO";
import Oncall from "./Oncall";
import Wanted01 from "./Wanted01";
import Aboutus from "./Aboutus";
import Location from "./Location";

import Contactus from "./Contactus";


import Sweetdetail from "./Sweetdetail";
import Sweetcategory from "./Sweetcategory";
import Sweetcategorydis from "./Sweetcategorydis";
import Basket from "./Basket";
import Checkout from "./Checkout";
 

import Login from "./components/login/Login";
import maindata from "./components/login/maindata";

import {Button, Row, Col, Toast} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import getToken_custom from './firebase.js';
//import onMessageListener from './onmessage.js';
import onMessageListener from './firebase.js';
import {Link, useNavigate } from "react-router-dom";
import Home from "./Home";
//import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary'

function MyFallbackComponent({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

function App() {
  
  const [show, setShow] = useState(false);
  const [isTokenFound, setTokenFound] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
//  getToken_custom(setTokenFound);

//   onMessageListener().then(payload => {
//     //setShow(true);
//     setNotification(
//       {
//         title: payload?.notification?.title,
//          body: payload?.notification?.body
//       }
//       );
//   if (payload!==undefined){  console.log(payload);}
//   }).catch(err => console.log('failed: ', err));

  
  return (
    
 
<>
<ErrorBoundary
      FallbackComponent={MyFallbackComponent}
      onReset={() => {
        // reset the state of your app here
      }}
      resetKeys={['someKey']}
    >
   <Toast onClose={() => setShow(false)} 
      show={show} delay={3000} autohide animation style={{
          position: 'absolute',
          top: 20,
          right: 20,
        }}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">Notification</strong>
            <small>12 mins ago</small>
          </Toast.Header>
          <Toast.Body>There are some new updates that you might love!</Toast.Body>
        </Toast>

{/*}
<div style={{display:"flex", height:"30px",backgroundColor:"black",width:"100%",}}>
  {isTokenFound && 
  <p style={{color:"#0fff0f"}}>  تلقي الإشعارات 👍🏻  </p>
  }
  {!isTokenFound && 
  <p style={{color:"red",}}>   تلقي الإشعارات  ❗️     </p>
  } 
  <Button style={{height:"20px",fontSize:"12px",padding:0}} onClick={() => setShow(true)}>Show Toast</Button>
</div>
  */} 
<Routes>
<Route path="/login" element={<Login  data={maindata} />} />
<Route path="/Reserve" element={<Reserve/>} />
<Route path="/" element={<Home/>} />
<Route path="" element={<Home/>} />
<Route path="/Sweetdetail" element={<Sweetdetail/>} />
<Route path="/Sweetcategory" element={<Sweetcategory/>} />
<Route path="/Sweetcategorydis" element={<Sweetcategorydis/>} />
<Route path="/Basket" element={<Basket/>} />
<Route path="/Checkout" element={<Checkout/>} />
<Route path="/Aboutus" element={<Aboutus/>} />
<Route path="/Location" element={<Location/>} />
<Route path="/Contactus" element={<Contactus/>} />
    
</Routes>

    </ErrorBoundary>



</>
 
  );
}

export default App;
