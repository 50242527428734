import { useForm } from "react-hook-form";
import { styled } from "@mui/system";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import React, {useLocation , useEffect, useRef, useState } from 'react';
import { BrowserRouter  , Routes, Route , useParams   } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
// import {Button} from 'react-bootstrap';
import {Card} from 'react-bootstrap';
import {CardGroup} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ErrorBoundary } from 'react-error-boundary';
import Cookies from 'universal-cookie';
import Navbar from './components/Navbar'
import AboveNavbar from './components/AboveNavbar'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { blue, pink,grey,red } from '@mui/material/colors';
import { green } from '@mui/material/colors';
import TableBarIcon from '@mui/icons-material/TableBar';
import SvgIcon from '@mui/material/SvgIcon';
import { Box, Button, Chip, FormControl, IconButton, Input, InputAdornment, InputLabel, Paper, Stack, TextField, Typography } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import { Visibility, VisibilityOff } from "@mui/icons-material";
const imageURL1 = '/purified/right_view.png';
const imageURL2 = '/purified/left_view.png';


const Background1 = styled("div")({
  
  minWidth: "50px",
  width: '30%' ,
  // height: "100%",
  minHeight:'150px',
  backgroundImage: `url(${imageURL1})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
   
});

const Background2 = styled("div")({
  
  minWidth: "50px",
  width: '30%' ,
  // height: "100%",
  minHeight:'150px',
  backgroundImage: `url(${imageURL2})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
   
});



export default function Reserve() {
  const {register: register1, handleSubmit: handleSubmit1 } = useForm();
  const {register: register2, handleSubmit: handleSubmit2 } = useForm();
  
 // formState: { errors: errors2 },
 const [startDate, setStartDate] = useState(new Date());
 const [thematrix, setthematrix] = useState([]);
 const   matrix  = useRef([]);
 const   thereturneddata  = useRef([]);

  const onSubmit1 = (data) => {
    console.log(data);
    const d = new Date(startDate);
    
    console.log(d);
    if (data.occ_time.length<1)
    {
      alert("Please Select Time");
    return;
    }

    get_available_tables(data.occ_time , d);

  };
  const onSubmit2 = (data) => {
    console.log(data);
  };

  const calc_matrix=()=>
  {
    var row1=[];var row2=[];matrix.current=[];

    ///////////      row1   /////////////////////////////////
    for (let it1=0;it1<3;it1++)
    { // console.log( it1 );  console.log(responseJson[0][it1]);
      if (thereturneddata.current[0][it1]=="1")
      { //console.log( it1 );
        row1.push(                
        <ToggleButton value={it1} aria-label={it1+" aligned"}  key={it1}
        sx={{width:'50px',height:'50px', backgroundColor: grey[100]   }}
        >
        { 
        (thealignment.current ==it1) && <TableBarIcon  key={"ign"+it1} sx={{ color: green[600]  }}/>
        }
        { 
        (thealignment.current !=it1) && <TableBarIcon  key={"igy"+it1} sx={{ color: grey[800]  }}/>
        }
        </ToggleButton>  
         );
      }
      else
      {
        row1.push(<div  key={it1} style={{display:'flex',justifyContent:'center',
        marginLeft:'20px',marginRight:'20px', alignItems:'center'}}>
        <TableBarIcon sx={{ color: red[800]  }}/></div>  );
      }
    }
    row2.push(<div key="rrow1" style={{display:'flex',justifyContent:'space-around',
    width:'100%',marginBottom:'30px'  }} >{row1}</div>);
    matrix.current.push(row2);
    row1=[];  row2=[];
    ///////////      row2   /////////////////////////////////
    for (let it1=3;it1<6;it1++)
    { // console.log( it1 );  console.log(responseJson[0][it1]);
      if (thereturneddata.current[0][it1]=="1")
      {
        row1.push(                
        <ToggleButton value={it1} aria-label="left aligned" key={it1}
        sx={{width:'50px',height:'50px', backgroundColor: grey[100]   }}
        >
        { 
        (thealignment.current ===it1) && <TableBarIcon sx={{ color: green[600]  }}/>
        }
        { 
        (thealignment.current !==it1) && <TableBarIcon sx={{ color: grey[800]  }}/>
        }
        </ToggleButton>  
         );
      }
      else
      {
        row1.push(<div  style={{display:'flex',justifyContent:'center',
        marginLeft:'20px',marginRight:'20px', alignItems:'center'}}>
        <TableBarIcon sx={{ color: red[800]  }}  key={it1}/></div>  );
      }
    }
    row2.push(<div key="rrow2" style={{display:'flex',justifyContent:'space-around',
    width:'100%',marginBottom:'30px'  }} >{row1}</div>);
    matrix.current.push(row2);
    row1=[];  row2=[];
    
    ///////////      row3   /////////////////////////////////
    for (let it1=6;it1<9;it1++)
    { // console.log( it1 );  console.log(responseJson[0][it1]);
      if (thereturneddata.current[0][it1]=="1")
      {
        row1.push(                
        <ToggleButton value={it1} aria-label="left aligned" key={it1}
        sx={{width:'50px',height:'50px', backgroundColor: grey[100]   }}
        >
        { 
        (thealignment.current ===it1) && <TableBarIcon sx={{ color: green[600]  }}/>
        }
        { 
        (thealignment.current !==it1) && <TableBarIcon sx={{ color: grey[800]  }}/>
        }
        </ToggleButton>  
         );
      }
      else
      {
        row1.push(<div  key={it1} style={{display:'flex',justifyContent:'center',
        marginLeft:'20px',marginRight:'20px', alignItems:'center'}}>
        <TableBarIcon sx={{ color: red[800]  }}/></div>  );
      }
    }
    row2.push(<div key="rrow3" style={{display:'flex',justifyContent:'space-around',
    width:'100%',marginBottom:'30px'  }} >{row1}</div>);
    matrix.current.push(row2);
    
    
    
  }

  const get_available_tables=async(xx,yy)=>{
   
      const userLogsInOptions = (xx,yy ) => {

        console.log("thexx");
        console.log(xx);
 
        const formData = new URLSearchParams();
 
        formData.append('date', yy );
        formData.append('time',xx  );
        return (
          {
            method: 'POST',
            headers: {
              // "Content-Type": "application/json; charset=utf-8",
              "Content-Type": "application/x-www-form-urlencoded",
          },
            body: formData.toString(),
          json: true,
        }
        );
      };
      
      const response = await fetch(process.env.REACT_APP_BASE_URL+'/api/Product/getavailabletables',
      userLogsInOptions(xx,yy) )
      const responseJson = await response.json();
  
      //---------------------------------------------------
      console.log("theavailable");
      console.log(responseJson);
      //  thesweet.current=responseJson;
//========================================================
thereturneddata.current=responseJson;

calc_matrix();
//========================================================
      setthematrix(matrix.current);
      show_tables_ready.current=true;
      settheshow_tables_ready(true);
  }

  const show_tables_ready=useRef(false);
  const [theshow_tables_ready,settheshow_tables_ready]=useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [alignment, setAlignment] = useState(null);
  const thealignment  = useRef(null);

  //const handleDateChange = e => setFormData({...formData, [e.target.name]:e.target.value})

  const handleAlignment = (event, newAlignment) => {
    thealignment.current=newAlignment;
    console.log("thealignment.current");
    console.log(newAlignment);
    console.log(thealignment.current);
    setAlignment(newAlignment);
    //////////////////////////////////////////
    calc_matrix();
    //////////////////////////////////////////////
    setthematrix(matrix.current);
  };

  return (
    <>
    <React.Fragment>
    <AboveNavbar   />
    </React.Fragment>


    <React.Fragment>
    <Navbar   />
    </React.Fragment>

    
    <div style={{display:'flex',justifyContent:'center',flexDirection:'column',
                alignItems:'center', backgroundColor: grey[700],width:'100%',height:'1000px'}}>
      <form onSubmit={handleSubmit1(onSubmit1)}>
     
          <div style={{  width:'300px',backgroundColor:grey[200],
                        marginBottom:"50px" }}> 
          <LocalizationProvider 
          dateAdapter={AdapterDayjs}
          >
          <DemoContainer
            components={[
              'DatePicker',
               
            ]}
          >
          <DatePicker 
           format="DD-MM-YYYY"
           //selected={'2024-02-12')} 
          defaultValue={dayjs(startDate)}
          //minDate={dayjs('2024-02-10')}
          onChange={(date) => setStartDate(date)}
         // onChange={handleDateChange}
         // {...register1("occ_date")}
          />


          </DemoContainer>
          </LocalizationProvider> 
          </div>  

          <div style={{  width:'300px',backgroundColor:grey[200],
                    display:'flex',justifyContent:'center', marginBottom:"50px"
                }}>   
          <TextField
                sx={{  width:'300px'}}
                label="UTC Time"
                type="time"
                variant="filled"
                {...register1("occ_time")}
                InputLabelProps={{ shrink: true }}
          />
          </div> 

          <div style={{  width:'300px',//backgroundColor:grey[200],
                        display:'flex',justifyContent:'center', marginBottom:"50px" }}>   
          <Button type="submit" variant="contained" sx={{fontWeight:'bold'}}>
            استعرض المتاح بعد اختيار الوقت و التاريخ
          </Button>
          </div>   

         

      </form>

      <form onSubmit={handleSubmit2(onSubmit2)}>
        <div style={{display:'flex',justifyContent:'center',
                    marginRight:'0px',marginLeft:'0px',
                    marginTop:'50px',
                    maxHeight:'400px',width:'100%',
                    paddingBottom:'0px',paddingRight:'0px',
                    paddingLeft:'0px',paddingTop:'0px',}}>

          <Background2 />

          {show_tables_ready.current &&    <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                sx={{display:'flex',justifyContent:'center',flexDirection:'column',
                    backgroundColor:grey[300]   }}
              >
              {thematrix}
                {/* <div style={{display:'flex',justifyContent:'space-around',
                            width:'100%',marginBottom:'30px'  }} >

                <div  style={{display:'flex',justifyContent:'center',
                              marginLeft:'20px',marginRight:'20px', alignItems:'center'}}>
                <TableBarIcon sx={{ color: red[800]  }}/>
                </div>    

                <ToggleButton value="left" aria-label="left aligned"
                sx={{width:'50px',height:'50px', backgroundColor: grey[400] , color: grey[200] }}
                >
                { 
                (thealignment.current ==='left') && <TableBarIcon sx={{ color: green[600]  }}/>
                }
                { 
                (thealignment.current !=='left') && <TableBarIcon sx={{ color: grey[800]  }}/>
                }
                </ToggleButton>  
                    
                


                <div  style={{display:'flex',justifyContent:'center',
                              marginLeft:'20px',marginRight:'20px',alignItems:'center'}}>
                
                <TableBarIcon sx={{ color: red[800]  }}/>
                </div>

                </div>

                <div style={{display:'flex',justifyContent:'space-around',
                      width:'100%',marginBottom:'30px'   }} >
                
                <ToggleButton value="right" aria-label="right aligned"
                sx={{width:'50px',height:'50px', backgroundColor: grey[400] , color: grey[200] }}
                >
                { 
                (thealignment.current ==='right') && <TableBarIcon sx={{ color: green[600]  }}/>
                }
                { 
                (thealignment.current !=='right') && <TableBarIcon sx={{ color: grey[800]  }}/>
                }
                </ToggleButton>  
                
                <div  style={{display:'flex',justifyContent:'center',
                              marginLeft:'20px',marginRight:'20px', alignItems:'center'}}>
                <TableBarIcon sx={{ color: red[800]  }}/>
                </div>

                <div  style={{display:'flex',justifyContent:'center',
                              marginLeft:'20px',marginRight:'20px',alignItems:'center'}}>
                
                <TableBarIcon sx={{ color: red[800]  }}/>
                </div>


                </div>
              
                <div style={{display:'flex',justifyContent:'space-around',
                          width:'100%',marginBottom:'30px'   }} >
                

                <div  style={{display:'flex',justifyContent:'center',
                              marginLeft:'20px',marginRight:'20px', alignItems:'center'}}>
                <TableBarIcon sx={{ color: red[800]  }}/>
                </div>

                <div  style={{display:'flex',justifyContent:'center',
                              marginLeft:'20px',marginRight:'20px',alignItems:'center'}}>
                
                <TableBarIcon sx={{ color: red[800]  }}/>
                </div>

                <ToggleButton value="yemeen" aria-label="yemeen aligned"
                sx={{width:'50px',height:'50px', backgroundColor: grey[400] , color: grey[200] }}
                >
                { 
                (thealignment.current ==='yemeen') && <TableBarIcon sx={{ color: green[600]  }}/>
                }
                { 
                (thealignment.current !=='yemeen') && <TableBarIcon sx={{ color: grey[800]  }}/>
                }
                </ToggleButton>  
                

                </div> */}
              
              </ToggleButtonGroup>
          }

          {!show_tables_ready.current &&  
            <div
            style={{display:'flex',justifyContent:'center',
            marginRight:'0px',marginLeft:'0px',
            marginTop:'50px',
            maxHeight:'400px',width:'100%',
            textWrap:'wrap',direction:'rtl',
            textAlign:'center',color:grey[50],
            fontSize:'20px',fontWeight:'bold',
             }}
            >اختار اليوم و الساعة لنعرض لك المتاح</div>

          }
          <Background1 />
        </div> 

        <div style={{  width:'300px',//backgroundColor:grey[200],
                    display:'flex',justifyContent:'center',marginTop:'20px' ,marginBottom:"30px" }}>   
          <Button type="submit" variant="contained" sx={{fontWeight:'bold'}}>
            احجز
          </Button>
      </div>   
      </form>
    </div>

    
    </>
  );
}
