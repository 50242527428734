import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter  , Routes, Route , useParams   } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import {Button} from 'react-bootstrap';
import {Card} from 'react-bootstrap';
import {CardGroup} from 'react-bootstrap';
//import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';


import { blue, blueGrey, grey } from '@mui/material/colors';
import Cookies from 'universal-cookie';
import InputGroup from 'react-bootstrap/InputGroup';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Buttonm from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import Typography from '@mui/material/Typography';
import Table from 'react-bootstrap/Table';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import { useStopwatch } from 'react-timer-hook';
import Navbar from './components/Navbar'

import AboveNavbar from './components/AboveNavbar'
import { ThefirstContext } from "./ThefirstContext";
import './base.css';
import { Label } from '@mui/icons-material';

const Basket = () => {

  const firstContext = React.useContext(ThefirstContext);

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });

  
   var product = [{
    name: null,  code: null,  qty: null,  pricebeforedis:null,
    priceafterdis:null,  discount:null,  expire:null,  note: null,
    user:null,  pass:null,  points:null,  mobile:null,  address:null,
    location:null,  branch:null, 
   }];

   var product_code=[];

  const pro_code_arr=useRef([]);const pro_name_arr=useRef([]);const pro_qty_arr=useRef([]);
   
  const ctbody=useRef([]); const inside_body=useRef([]); 
  const [ssinside_body,setinside_body]=useState([]);const [body,setbody]=useState([]);
  
  const navigation = useNavigate();
 
 
  const cookies = new Cookies();
   
  
  const [qtynm,setqtynm]=useState(firstContext.basketdata_qty);

  const ppproduct = [{
    name: null,  code: null,  qty: null,  pricebeforedis:null,
    priceafterdis:null,  discount:null,  expire:null,  note: null,
    user:null,  pass:null,  points:null,  mobile:null,  address:null,
    location:null,  branch:null, 
   }];

   const add_product0=(name,code )=>
   {
     firstContext.basketdata_name.push(name);
     firstContext.basketdata_code.push(code);
   }
   const add_product=(name,code )=>
  {
   // firstContext.basketdata_name.push(name);  firstContext.basketdata_code.push(code);
  
  
  
  }
  
// useEffect(() => {

 
//   console.log(firstContext.basketdata_code);
//   //------------------------------------- firstContext.product
  
 

//   console.log("Duplication code array:" );  
//   console.log(firstContext.basketdata_code.length );
//   console.log("new array:" );  console.log(firstContext.basketdata_code );
//   //--------------------------------
//   let duplicates_prod_code = [];
   
//   firstContext.basketdata_code.forEach(function (value, index, array) {
//  // console.log("value :" );    console.log(value  );
//   if (array.indexOf(value , index + 1) !== -1
//           && duplicates_prod_code.indexOf(value ) === -1) {
//            // console.log("found duplication" );
//             duplicates_prod_code.push(value );
//       }
//       else{}
//   });
//   console.log("codes:" );  console.log(duplicates_prod_code );
  
//   pro_code_arr.current=[];pro_name_arr.current=[];pro_qty_arr.current=[];

//   pro_code_arr.current=duplicates_prod_code;
//   var prod_name="";
//   for (let p=0;p<pro_code_arr.current.length;p++)
//   { for (let v=0;v<firstContext.basketdata_code.length;v++) {
//       if (pro_code_arr.current[p]===firstContext.basketdata_code[v]) { prod_name=firstContext.basketdata_name[v]; } }
//     pro_name_arr.current.push(prod_name);
//   }
//   console.log("pro_name_arr:" ); console.log(pro_name_arr );
//   //----------------------------------
//   var prod_qty=0;
//   for (let p=0;p<pro_code_arr.current.length;p++)
//   { for (let v=0;v<firstContext.basketdata_code.length;v++) {
//       if (pro_code_arr.current[p]===firstContext.basketdata_code[v] ) { prod_qty=prod_qty+1 ; } 
//     }
//     pro_qty_arr.current.push(prod_qty);prod_qty=0;
//   }
//     setqtynm(pro_qty_arr.current);
//     console.log("pro_qty_arr:" ); console.log(pro_qty_arr );
//   //--------------------------------------------------

//   ctbody.current=[];  inside_body.current=[];

//    console.log("pro_code_arr.current.length");
//    console.log( pro_code_arr.current.length);

//   for (let u=0; u < pro_code_arr.current.length ; u++)
//   {
//     console.log("firstContext.basketdata_name[u] "+u);
//     console.log(pro_name_arr.current);
    

//     inside_body.current.push(            
//       <tr key={"r"+u} >
//       <th  key={"h"+u} scope="row">{u+1}</th>
//       <td key={"d1"+u}>{pro_name_arr.current[u]}</td>
//       <td key={"d2"+u}>
//       <Box  key={"bxd2"+u} style={{width:'100%',display:'flex',justifyContent:'center'}}>

//       <IconButton  key={"icminus"+u}
//       onClick={() => { if (pro_qty_arr.current[u]>1) 
//           {pro_qty_arr.current[u]=pro_qty_arr.current[u]-1; 
//             // setbody(ctbody.current);
//            // setqtynm[u]=firstContext.basketdata_qty[u];
//             console.log(pro_qty_arr.current[u]);
//           }
//          }}
//       aria-label="remove" color ="primary">
//       <RemoveIcon />
//       </IconButton>

//       <label   key={"labl"+u} style={{fontSize:'25px',marginRight:'5px',marginLeft:'5px'}}    >
//       {/* {`${pro_qty_arr.current[u]}`} */}
//       { qtynm[u]}
//        {/* {firstContext.basketdata_qty[u]} */}
//       </label> 

//       <IconButton 
//       key={"icadd"+u}
//       onClick={(e) => {
//        var q1=  Number(pro_qty_arr.current[u])+1;
//        pro_qty_arr.current[u]=q1;
//         setqtynm[u]=q1;
//         console.log(pro_qty_arr.current[u]);
//         add_product(pro_name_arr.current[u],pro_code_arr[u]);
//       }}
//       aria-label="add" color ="primary">
//       <AddIcon />
//       </IconButton>

//       </Box>

//       </td>
//       <td key={"d3"+u}>@mdo</td>
//       </tr>
//             );
//   }
// setinside_body(inside_body.current);
//   ctbody.current.push (
//           <tbody key="bdy">
//             {inside_body.current}
 
//           </tbody>
//           );

//   setbody(ctbody.current);
 
// }, [seconds] );  ///  firstContext.basketdata_qty

useEffect(() => {

 
  console.log("inside_basket_firstContext.basketdata_code");
  console.log(firstContext.basketdata_code);
  //-------------------------------------  
 
  //--------------------------------------------------
  inside_body.current=[];
 
  for (let u=0; u < firstContext.basketdata_code.length ; u++)
  {
 

    inside_body.current.push(            
      <tr key={"r"+u} >
      <th  key={"h"+u} scope="row">{u+1}</th>
      <td key={"d1"+u}>{firstContext.basketdata_name[u]}</td>
      <td key={"d2"+u}>
      <Box  key={"bxd2"+u} style={{width:'100%',display:'flex',justifyContent:'center'}}>

      <IconButton  key={"icminus"+u}
      onClick={() => { if (firstContext.basketdata_qty[u]>1) 
          {
            var q1=  Number(firstContext.basketdata_qty[u])-1;
          firstContext.basketdata_qty[u]=q1;
           setqtynm[u]=q1;
           console.log("new qty"+u);
           console.log(firstContext.basketdata_qty[u]);

          }
         }}
       
      aria-label="remove" color ="primary">
      <RemoveIcon />
      </IconButton>

      <label   key={"labl"+u} style={{fontSize:'25px',marginRight:'5px',marginLeft:'5px'}}    >
      
      { qtynm[u]}
       {/* {firstContext.basketdata_qty[u]} */}
      </label> 

      <IconButton 
      key={"icadd"+u}
      onClick={(e) => {
       var q1=  Number(firstContext.basketdata_qty[u])+1;
       firstContext.basketdata_qty[u]=q1;
        setqtynm[u]=q1;
        console.log("new qty"+u);
        console.log(firstContext.basketdata_qty[u]);
       
      }}
      aria-label="add" color ="primary">
      <AddIcon />
      </IconButton>

      </Box>

      </td>
      <td key={"d3"+u}>
       { 
         (Number(firstContext.basketdata_priceafterdis[u])*
         Number(firstContext.basketdata_qty[u])).toFixed(2)
         }
      
      </td>
      <td key={"d4"+u}>
      <Box  key={"bxd2"+u} style={{width:'100%',display:'flex',justifyContent:'center'}}>
  
      <IconButton 
      key={"icdel"+u}
      onClick={(e) => {
        firstContext.basketdata_qty.splice(u, 1); 
        firstContext.basketdata_priceafterdis.splice(u, 1); 
        firstContext.basketdata_code.splice(u, 1); 
        firstContext.basketdata_name.splice(u, 1); 
        firstContext.basketdata_discount.splice(u, 1);   
        firstContext.basketdata_pricebeforedis.splice(u, 1);   
   
        /*
      var q1=  Number(firstContext.basketdata_qty[u])+1;
      firstContext.basketdata_qty[u]=q1;
        setqtynm[u]=q1;
        console.log("new qty"+u);
        console.log(firstContext.basketdata_qty[u]);
      */
      }}
      aria-label="add" color ="primary">
      <DeleteForeverTwoToneIcon />
      </IconButton>

      </Box>

      
      </td>
      </tr>
            );
  }
setinside_body(inside_body.current);
ctbody.current=[];

ctbody.current.push (<tbody key="bdy"> {inside_body.current}</tbody>);

setbody(ctbody.current);
 
}, [seconds] );  ///  firstContext.basketdata_qty

 
 
const placeorder =( )=>
{
  //console.log(firstContext.product);
  navigation("/Checkout" );  
}
  
  return (
   
    <> 
    <ThemeProvider dir="rtl"> 
      <React.Fragment>
         <AboveNavbar   />
      </React.Fragment>

   
      <React.Fragment>
         <Navbar   />
      </React.Fragment>
 
   
     <div style={{width:'100%',height:'auto', margin:'0px',display:'flex',justifyContent:'center'}} key={"mm"} > 
      
          <Card key={"mmc"}  style={{width:'100%',height:'auto', margin:'0px',}}  >

           <Card.Body  style={{display:'flex',justifyContent:'center',flexDirection:'column'}}>
          <Card.Title  style={{display:'flex',justifyContent:'center'}}>Shopping Cart</Card.Title>
     
          <Card.Text style={{display:'flex',justifyContent:'center'}}>  
          Details

          </Card.Text>

          <Table key="t1" striped bordered hover variant="dark"  dir="rtl" responsive>
          <thead  style={{  textAlign:'center'}} key="th1">
            <tr  key="trr1">
              <th   key="th11" scope="col">#</th>
              <th   key="th12"  scope="col">الصنف</th>
              <th   key="th13"  scope="col">الكمية</th>
              <th   key="th14"  scope="col">السعر</th>
              <th   key="th15"  scope="col">إزالة</th>
            </tr>
          </thead>
       
          {body}
          </Table>

        

          <Button variant="primary"  style={{display:'flex',justifyContent:'center'}}
          onClick={() => {placeorder();}}

          >  تابع إكمال الطلب</Button>
          </Card.Body>

          </Card> 

   </div>


     
       { }
     </ThemeProvider>
    </>
  );
};
 
export default Basket;

