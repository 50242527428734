import React,{useEffect,useState,useRef} from "react";
import "./base.css";
import "./lightbox.css";
import cors from 'cors'
import Cookies from 'universal-cookie';
import {getairlines} from './functions1';
import {get_outstations} from './functions1';
import axios from 'axios'
// npm run build
import Navbar from './components/Navbar'


import { useNavigate } from "react-router-dom";
 
import raw from './mainpath5.txt';
import raw6 from './mainpath6.txt';
import raw3 from './mainpath3.txt';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import {Button} from 'react-bootstrap';
import {Card} from 'react-bootstrap';
import {CardGroup} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Wanted01customized from './wanted01customized';
import { ThefirstContext } from "./ThefirstContext";

//-----------------------------------------------------

export default function Location(props) {
//---------------------------------------------------------------
const cookies = new Cookies();
  const p2=()=>{
    var url =  'https://google.com';
    window.open(url, '_blank'); 
  }  

  const navigation = useNavigate();
  //var cat=2;
  const theimage=useRef(process.env.REACT_APP_BASE_URL+'/images/products/giphy.gif');
  const [ustttheimage,setustttheimage]=useState(process.env.REACT_APP_BASE_URL+'/images/products/giphy.gif');
  
  const [the_src_us,setthe_src_us]=useState( 'https://www.google.com/maps/embed/v1/place?q=500%20%2C%20500%20&key=AIzaSyDyw35Z5Qw1Xb_oACrQ-5FDjV7On7uRM24');
  
  const [the_src_us2,setthe_src_us_2]=useState( 'https://www.google.com/maps/embed/v1/place?q=500%20%2C%20500%20&key=AIzaSyDyw35Z5Qw1Xb_oACrQ-5FDjV7On7uRM24');
  

  const  [ustwo_obj,setustwo_obj]=useState([]);
    
  const  two_obj=useRef([]);
    
  const firstContext = React.useContext(ThefirstContext);

  const persons = [{
    name: 'John Doe',
    age: 30,
    address: {
     street: '123 Main St',
     city: 'Anytown',
     state: 'Anystate'
    },
   
   }];


   const new_loc=useRef(true);
   const new_loc2=useRef(true);
  
//  firstContext.pressedbtn==="1"  
//  if ( ( firstContext.thelessondetails===null) || ( firstContext.thelessondetails===undefined)    )
//  {firstContext.thelessondetails=props.lessondetails;}

  //  https://commercex.internet-online.net/api/Product/selectsweetbestseller
  const bestseller_arr=useRef([]);
  
   //-----------------------
   var the_src1="https://www.google.com/maps/embed/v1/place?q=";
   var the_src2 ;
   var the_src3="+";
   var the_src4=",";
   var the_src5="+";
   var the_src6 ;
   var the_src7="+&key=AIzaSyDyw35Z5Qw1Xb_oACrQ-5FDjV7On7uRM24"; 
   var the_src ;


   var the_src1_2="https://www.google.com/maps/embed/v1/place?q=";
   var the_src2_2 ;
   var the_src3_2="+";
   var the_src4_2=",";
   var the_src5_2="+";
   var the_src6_2 ;
   var the_src7_2="+&key=AIzaSyDyw35Z5Qw1Xb_oACrQ-5FDjV7On7uRM24"; 
   var the_src_2 ;



  useEffect(() => {

  let  the_cookie= cookies.get('tableposition');
  console.log("thecookie");
  console.log(the_cookie);
  
   // firstContext.person.pop();firstContext.person.push(persons);
   //console.log("firstContext.person"); console.log(firstContext.person);
   //-------------------------
    sweets();
    the_src2="30.123";
    
    the_src6="31.123";

    setthe_src_us(the_src1+the_src2+the_src3+the_src4+the_src5+the_src6+the_src7);
     
    console.log("new location");  // select_sweet_by_category   productcategory
    new_loc.current=true;

   //------------------------
   the_src2_2="30.6123";
    
   the_src6_2="31.6123";

   setthe_src_us_2(the_src1_2+the_src2_2+the_src3_2+the_src4_2+the_src5_2+the_src6_2+the_src7_2);
    
   console.log("new location");  // select_sweet_by_category   productcategory
   new_loc2.current=true;


 }, []);
 var sweets=async()=>{
   // var   res0 = await axios.get(process.env.REACT_APP_BASE_URL+'/api/Product/selectsweet');
    console.log("thesweet");
      //------------------------------------------------
      const userLogsInOptions = (xx ) => {
 
        console.log("thexx");
        console.log(xx);
 
        const formData = new URLSearchParams();
 
        formData.append('productcategory', xx );
        return (
          {
            method: 'POST',
            headers: {
              // "Content-Type": "application/json; charset=utf-8",
              "Content-Type": "application/x-www-form-urlencoded",
          },
            body: formData.toString(),
          json: true,
        }
        );
      };
      
 //-------------------------------------------------
 const response = await fetch(process.env.REACT_APP_BASE_URL+'/api/Product/selectsweetbestseller',
 userLogsInOptions(" ") )
 const responseJson = await response.json();
  
 //------------------------------------------------------
 bestseller_arr.current=[];
 
 bestseller_arr.current=  responseJson ;
    console.log(bestseller_arr.current);  // select_sweet_by_category   productcategory
    console.log("theimage.current");
    var sweet_obj=bestseller_arr.current;
     
   
    const  arrayDataItems = bestseller_arr.current.map((item,index) =>
    {
     return(
      <Card  key={index}>
      <Card.Img variant="top" src={item['productimage']} />
      <Card.Body>
        <Card.Title>{bestseller_arr.current[index]['productname'] }</Card.Title>
        <Card.Text>
        {bestseller_arr.current[index]['productdescription']}
        </Card.Text>
        <Card.Text>
          {bestseller_arr.current[index]['productprice']}
        </Card.Text>
        <Button variant="primary" onClick={()=>handle_best_seller(index)}>show product</Button>
      </Card.Body>
    </Card>    
    
      )
 
    } );
     two_obj.current= arrayDataItems ; 
     setustwo_obj(arrayDataItems); 

 }
 var tcx=0;
  {/*     
    var catitem = item['product_inside_category_order'] +","+item['productcategory']     ;
    cookies.set('catitem', catitem, { path: '/' });navigation("/Sweetdetail", { index:  item['product_inside_category_order']   ,cat: item['productcategory']  });   
            */}
 const handle_best_seller =(e)=> 
 {
  var catitem = bestseller_arr.current[e]['product_inside_category_order'] +
           ","+bestseller_arr.current[e]['productcategory']     ;
  cookies.set('catitem', catitem, { path: '/' });
  navigation("/Sweetdetail", 
            { index:  bestseller_arr.current['product_inside_category_order'] ,
              cat: bestseller_arr.current['productcategory']  });   
    
  // alert (bestseller_arr.current[e]['productprice']);

 } 
// C3A241
  return (
    <>
          <React.Fragment>
         <Navbar   />
      </React.Fragment>

     <br></br>
 
 

    <div  className="rrow">
    <Card className="one_cat">
   
      <Card.Body>
        <Card.Title>  Location </Card.Title>

        <div  className="rrow">   
           Branch 1
        </div>
        <div  className="rrow">   
    {new_loc.current && <iframe width="600" height="450" 
            style={{ border:0}}  loading="lazy" allowfullscreen 
            src={the_src_us}></iframe>
    }

        </div>
        
<br></br>
<br></br>

<div  className="rrow">   
           Branch 2
        </div>
        <div  className="rrow">   
    {new_loc2.current && <iframe width="600" height="450" 
            style={{ border:0}}  loading="lazy" allowfullscreen 
            src={the_src_us2}></iframe>
    }

        </div>
<br></br>


      </Card.Body>
    </Card>   
    </div>
   
  
    <br></br>
  
  
  
 
     <br></br> 
       

    </>
  );
}