export default [
  {
    title: "Safety policy and objectives",
    path: "/SafetyPolicy",
  },
  {
    title: "Safety risk management",
    path: "/SafetyRisk",
  },
  {
    title: "Safety assurance",
    path: "/SafetyAssurance",
  },
  {
    title: "Safety Promotion",
    path: "/SafetyPromotion",
  },
  {
    title: "Investigation Dep",
    path: "/group5",
  },
  {
    title: "Risk Dep",
    path: "/group6",
  },
  {
    title: "Reports",
    path: "/Reports",
  },
];
